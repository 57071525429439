
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import { dataItem, factor } from "../../models/reports/treatmentbygniovertime";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default Vue.extend({
  name: "PopulationStatistics",
  components: {
    LineChartGenerator,
  },
  data: () => ({
    chartData: {
      labels: [] as string[],
      datasets: [
        {
          label: i18n.t(
            "reports.agsreports.TreatmentByGNIOverTime.upper_income"
          ),
          backgroundColor: "green",
          data: [] as number[],
        },
        {
          label: i18n.t(
            "reports.agsreports.TreatmentByGNIOverTime.middle_income"
          ),
          backgroundColor: "yellow",
          data: [] as number[],
        },
        {
          label: i18n.t(
            "reports.agsreports.TreatmentByGNIOverTime.lower_income"
          ),
          backgroundColor: "red",
          data: [] as number[],
        },
        {
          label: i18n.t("reports.agsreports.TreatmentByGNIOverTime.world"),
          backgroundColor: "blue",
          data: [] as number[],
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
    },
    factors: [] as factor[],
    factorID: null,
    isDataLoading: false,
  }),
  computed: {
    selectedSurvey(): number {
      return this.$store.getters["agsreport/getYearSelected"]?.year;
    },
    isLoading(): boolean {
      return this.isDataLoading;
    },
  },
  methods: {
    getFactors() {
      axios
        .get(`Report/WFH/TreatmentByGNIOverTime/factors`)
        .then((res: any) => {
          this.factors = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearGraph() {
      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];
      this.chartData.datasets[1].data = [];
      this.chartData.datasets[2].data = [];
      this.chartData.datasets[3].data = [];
    },
    getData(year: number) {
      this.isDataLoading = true;

      axios
        .get(`Report/WFH/TreatmentByGNIOverTime/${this.factorID}?year=${year}`)
        .then((res: any) => {
          this.clearGraph();
          res.data.forEach((item: dataItem) => {
            this.chartData.labels.push(item.surveyYear);
            this.chartData.datasets[0].data.push(item.high);
            this.chartData.datasets[1].data.push(item.medium);
            this.chartData.datasets[2].data.push(item.low);
            this.chartData.datasets[3].data.push(item.world);
          });
          this.isDataLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isDataLoading = false;
        });
    },
    exportData() {
      axios
        .get(
          `Report/WFH/TreatmentByGNIOverTime/${this.factorID}/export?year=${this.selectedSurvey}`,
          { responseType: "blob" }
        )
        .then((res: any) => {
          const fileName = `${i18n.t(
            "navbar.menu.reportsub.ags_reports"
          )} - ${i18n.t("reports.agsreports.TreatmentByGNIOverTime.title")} - ${
            this.factors.find((item: factor) => item.id === this.factorID!)
              ?.name
          }`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "aplication/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    this.getFactors();
    if (this.selectedSurvey && this.factorID !== null) {
      this.getData(this.selectedSurvey);
    }
  },
  watch: {
    selectedSurvey() {
      if (this.selectedSurvey && this.factorID !== null) {
        this.getData(this.selectedSurvey);
      }
    },
    factorID() {
      if (this.selectedSurvey && this.factorID !== null) {
        this.getData(this.selectedSurvey);
      }
    },
  },
});
